import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {Button, message, Modal, Row} from "antd";
import LeaseRecordHistoryListPage from "../LeaseRecords/LeaseRecordHistory"
import {useTranslation} from "react-i18next";

const MachineListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

    const buildCardBody = (data)=>{
        const cardBodyFields = {
          'contract_code': t('leaserecord_contract_field'),
          'machine.identifier': t('machine_identifier_field'),
          'machine.machine_type_text':t('machine_type_field'),
          'active_history.start_date': t('leaserecord_start_date_field'),
          'active_history.end_date': t('leaserecord_end_date_field'),
          'partner.company_name': t('leaserecord_partner_field')
        };
        let innerContents = [];
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine.machine_type_text'){
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {t(value)}</Row>)
                }
                else{
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {value}</Row>)
                }
            }
        }
        return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

            {innerContents}

        </Row>
    }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: data.active? 'green': 'red'}}>{t(data.status_text)}</label>]
  }
  const config = {
      pageSize: props.pageSize || 10,
    url: `/api/v1/partner_constructor_mediation/machine_lease_records/?constructor=${props.constructor.id}`,
    hideEdit: true,
    disableDelete: true,
    hideAddButton: true,
    pageTitle: t('constructor_detail_leaserecord_title'),
    pageSubTitle: t('constructor_detail_leaserecord_subtitle'),
    deleteTitle: "Are you sure you want to delete this Machine?",
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'machine_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'contract_code': t('leaserecord_contract_field'), 'machine.identifier': "Machine Identifier", 'machine.machine_type_text':"Machine Type", 'active_history.start_date': 'Start Date', 'active_history.end_date': 'End Date', 'partner.company_name': 'Partner'},
    cardFooterFields: [],
      orderingFields: [
        {id: '-machine__name', name: t('machine_orderby_field1')},
        {id: 'machine__name', name: t('machine_orderby_field2')},
        {id: '-machine__number', name: t('machine_orderby_field3')},
        {id: 'machine__number', name: t('machine_orderby_field4')},
        {id: '-machine__created', name: t('machine_orderby_field5')},
        {id: 'machine__created', name: t('machine_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }

  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    return null;
  }

  const handleDetailClick=(item)=>{
    setItem(item);
    setShowModal(true)
//    navigate('/machine/detail/', {state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <Modal
            open={showModal}
            title={t(`lease_record_detail_view_title`, {contract_code: item?.contract_code})}
            onCancel={handleCloseModal}
            // onOk={handleCloseModal}
            maskClosable={false}
            width={800}
            footer={[
                <Button key="cancel" onClick={handleCloseModal} >
                    {t('lease_record_modal_close_text')}
                </Button>,
            ]}
        >
          <LeaseRecordHistoryListPage loading={reload} title={t(`lease_record_detail_view_title`, {contract_code: item?.contract_code})} url={`/api/v1/partner_constructor_mediation/lease_record_histories/?lease_record=${item?.id}`} />
        </Modal>
      </>
  );
};

export default MachineListPage;
