import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import PartnerAddPage from './PartnerAddPage'
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const PartnerListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color:data.status ? 'green' : 'red'}}>{t(data.status_text)}</label>];
  }

  const buildListUrl=()=>{
    if(props.user){
        return `/api/v1/partners/?assigned_to=${props.user.id}`
    }
    return '/api/v1/partners/';
  }


  const config = {
    disableDelete:true,
    pageSize: props.pageSize || 10,
    url: buildListUrl(),
    pageTitle: props.title || t('partner_list_title'),
    deleteTitle: t('partner_delete_confirmation_text'),
    pageSubtitle: t('partner_list_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'company_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'full_name': t('partner_contactperson_field'), 'email': t('partner_addpage_email_field'), 'contact_no': t('partner_addpage_contact_field')},
    cardFooterFields: [],
    orderingFields: [{id: '-company_name', name: t('partner_name_desc_order_field')}, {id: 'company_name', name: t('partner_name_asc_order_field')}],
    addPermission: ['partner-create'],
    editPermission: ['partner-edit'],
    deletePermission: ['partner-delete'],
    viewPermission: ['partner-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }
  const handleOpenModal = (item, itemEdit) => {
    if(itemEdit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setEdit(false);
    setItem(null);
    setShowModal(false);
  };
  const handleSuccessAdd = () =>{
    setEdit(false);
    setItem(null);
    setReload(!reload);
    setShowModal(false);
  }

  const handleDeleteClick = (item)=>{
  message.error(t('not_implemented_error_text'))
//    http.delete(`/api/v1/partners/${item.id}/delete/`).then((resp)=>{
//      if(resp.status === 204) {
//        setReload(!reload);
//      }
//      else{
//        message.error('Partner Delete Failed')
//      }
//    }).catch((err)=>{
//      if(err.response){
//        message.error(err.response.data.message);
//      }
//    })
  }

  const handleDetailClick=(item)=>{
    navigate('/partner/detail/', { state: {partner: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <PartnerAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
        />
      </>
  );
};

export default PartnerListPage;
