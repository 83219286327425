import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const LicenseApplicationsListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const handleLicenseApplicationApprove=(record)=>{
        if(record.status === 1 || record.status ===  4)(
                http.post(`/api/v1/machine_license/${record.id}/2/update_status/`).then((resp)=>{
                    if(resp.status ===  200){
                        message.success(t('license_application_approve_successful_text'));
                        setReload(!reload)
                    }
                    else{
                        message.error(t('license_application_approve_fail_text'))
                    }
                }).catch((err)=>{
                    message.error(t('license_application_approve_fail_text'))
                })
            )
    }
    const renderApproveIcon=(record)=>{
    if(record.status === 1 || record.status ===  4){
        return <CustomConfirmPopup
                    title={t('license_application_approve_confirmation_text')}
                    onConfirm={()=>handleLicenseApplicationApprove(record)}
                    icon={<CheckOutlined style={{color: 'green'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                ><Typography.Link style={{color:'green'}}><CheckOutlined /></Typography.Link></CustomConfirmPopup>
        }
        return null;
    }
    const renderRejectIcon=(record)=>{

        return <Typography.Link style={{color:'red'}} onClick={()=>message.error(t('not_implemented_error_text'))}><CloseOutlined /></Typography.Link>
    }

    const columns = [
        {
            title: t('license_application_col1'),
            dataIndex: 'entry_date',
            key: 'entry_date',
            width: '20%'
        },
        {
            title: t('license_application_col2'),
            dataIndex: 'machine_data',
            key: 'machine_name',
            width: '20%',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.name}</div>
            ),
        },
        {
            title: t('license_application_col3'),
            dataIndex: 'machine_type_text',
            key: 'machine_type',
            width: '20%',
            render: (text) => (

                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
            ),
        },
        {
            title: t('license_application_col4'),
            dataIndex: 'machine_identifier',
            key: 'machine_identifier',
            width: '20%'
        },
        {
            title: t('license_application_col5'),
            dataIndex: 'status_text',
            key: 'status_text',
            width: '10%',
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: t('license_application_col6'),
            key: 'action',
            fixed:'right',
            width: '8%',
            render: (record) => (
                <Space size="middle">
                {renderApproveIcon(record)}
                </Space>
            )
        },
    ];

    const searchFields = [
        {
            name: 'machine_identifier',
            type: 'input',
            label: t('machine_identifier_field'),
            placeholder: t('machine_identifier_field')
        },

        {
            name: 'status',
            type: 'select',
            label: t('license_application_col5'),
            placeholder: t('select_placeholder'),
            extraProps: {
                url: '/api/v1/enums/MachineLicenseStatusChoices/combo/',
                withTranslation: true
            }
        }
    ];

    const data = [
        // Provide your table data if not using URL
    ];

    return (
            <DataTable
                reload={reload}
                pageTitle={t('partner_detail_machine_license_applicationlist_title')}
                columns={columns}
                url={`/api/v1/machine_license/?partner=${props.partner.id}`}
                searchFields={searchFields}
                defaultParams={{status: 1}}
                pagination={true}
                pageButtons={[

                ]}
            />
    );
};

export default LicenseApplicationsListPage;