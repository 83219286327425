import {Divider, Row, Col, Space, Button, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CheckOutlined} from "@ant-design/icons";
import http from '../../http';
import ConstructorListPage from "./Constructors";
import PartnerMachineListPage from "./MachineList"
import MachineLicenseApplications from './LicenseApplications';
import MediationRequestListPage from './MediationRequests';
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";

const PartnerDetailPage=(props)=>{
    const location = useLocation();
    const {state} = location;
    const [partner, setPartner] = useState(state?.partner);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.partner);

    const fetchPartnerData=()=>{
        http.get(`/api/v1/partners/${state?.partner?.id}/`).then((resp)=>{
            setLoading(false);
            setPartner(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found', {replace: true})
        }
        if(isValidState) {
            fetchPartnerData();
        }
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{partner?.company_name || state?.partner.company_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/partners'}>{t('menu_1')}  </Link> >  {partner?.company_name || state?.partner.company_name}</>;
    }

    const handleActivationClick=()=>{
        let url = partner.status ? `/api/v1/partners/${partner.id}/deactivate_partner/` : `/api/v1/partners/${partner.id}/activate_partner/`;
        let successMessage =  partner.status ? t(`cloud_access_remove_success_message`, {company_name:partner.company_name}): t(`cloud_access_create_success_message`, {company_name:partner.company_name});
        let errorMessage =  partner.status ? t(`cloud_access_remove_failed_text`, {company_name:partner.company_name}): t(`cloud_access_creation_failed_text`, {company_name:partner.company_name});
        http.post(url).then((resp)=>{
            if(resp.status ===  200){
                message.success(successMessage);
                fetchPartnerData()
            }
            else{
                message.error(errorMessage);
            }
        }).catch((err)=>{
            message.error(errorMessage);
        })
    }

    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            <CustomConfirmPopup
                                title={!partner.status ? t(`cloud_access_create_cofirmation_text`, {company_name: partner.company_name}) : t(`cloud_access_remove_confirmation_text`, {company_name: partner.company_name})}
                                onConfirm={() => handleActivationClick()}
                                icon={<CheckOutlined style={{color: 'green'}}/>}
                                okText={t('yes_text')}
                                cancelText={t('no_text')}
                                okType='default'
                            >
                                <Button type={'primary'}
                                        style={{background: '#377EF3'}}>{!partner.status ? t('partner_cloud_access_text1') : t('partner_cloud_access_text2')}</Button>
                            </CustomConfirmPopup>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{partner.company_name}</h1></Col>
                        <Col>
                            <Row style={{display: 'flex', flexDirection: 'row'}}>
                                <Col>
                                    <Col>{t('partner_addpage_email_field')}: {partner.email}</Col>
                                    <Col>{t('partner_addpage_contact_field')}: {partner.contact_no}</Col>
                                    <Col>{t('partner_addpage_address1_field')}: {partner.address_1}</Col>
                                    <Col>{t('partner_addpage_address2_field')}: {partner.address_2}</Col>
                                    {/*<Col>{t('partner_addpage_bin_field')}: {partner.bin}</Col>*/}
                                </Col>
                                <Divider type='vertical'/>
                                <Col>
                                    <Col>{t('partner_contactperson_field')}: {partner.full_name}</Col>
                                    <Col>{t('partner_addpage_remoteusername_field')}: {partner.remote_username}</Col>
                                    <Col>{t('partner_addpage_remotepassword_field')}: {partner.remote_password}</Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <ConstructorListPage pageSize={3} partner={partner} link={[{
                                to: '/partners/',
                                state: {},
                                title: t('menu_1')
                            }, {to: '/partner/detail/', state: {partner: partner}, title: partner.company_name}]}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <PartnerMachineListPage pageSize={3} partner={partner} link={[{
                                to: '/partners/',
                                state: {},
                                title: t('menu_1')
                            }, {to: '/partner/detail/', state: {partner: partner}, title: partner.company_name}]}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden"}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineLicenseApplications partner={partner}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: 'hidden'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MediationRequestListPage partner={partner} type={'mediation'}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: 'hidden'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MediationRequestListPage partner={partner} type={'cancel'}/>
                        </div>
                    </Row>
                    {/*<Divider />*/}
                    {/*<Row style={{overflow:'hidden'}}>*/}
                    {/*    <div style={{width: '100%', height:'100%'}}>*/}
                    {/*        <AccessTokens partner={partner} />*/}
                    {/*    </div>*/}
                    {/*</Row>*/}
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default PartnerDetailPage;
