import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, message, Checkbox} from 'antd';
import SelectField from "../Fields/SelectField";
import DateField from "../Fields/DateField";
import http from "../../http";
import {PlusOutlined} from "@ant-design/icons";
import MyUploadInput from "../Fields/UploadInput";
import {useTranslation} from "react-i18next";
import DateTimeField from "../Fields/DateTimeField";


const AddPageModal = ({ config, visible, onClose, onFinish, onSuccessSubmit, onErrorSubmit, edit, initialValues, defaultValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const { fields } = config;
    const modalWidth = config?.modalSize ? config.modalSize : 800;
    const { i18n, t } = useTranslation();

    useEffect(()=>{
        form.setFieldsValue(defaultValues);
    })

    useEffect(() => {
        // Set the initial values of the form fields
        form.setFieldsValue(initialValues)
    }, [form, initialValues]);


    const handleQuickAddClick = (field) => {
        if(field.handleQuickAddClick){
            field.handleQuickAddClick(field)
        }
        else{
            message.error('Not Implemented')
        }
    };


    const handleSubmit = (values) => {
        setLoading(true);
        let datetimeField = [];
        for(let i=0; i<fields.length; i++){
            if(fields[i].type === 'datetime'){
                datetimeField.push(fields[i].name)
            }
        }
        for (const fieldName in values) {
            if (values.hasOwnProperty(fieldName)) {
                const fieldValue = values[fieldName];
                if (fieldValue === undefined) {
                    // Handle the field with undefined value as needed
                    values[fieldName] = null
                }
            }
            // Adding Asia/Tokyo time
            if(values.hasOwnProperty(fieldName) && datetimeField.includes(fieldName) && !values[fieldName].includes('+')){
                values[fieldName] = values[fieldName]+"+09:00"
            }
        }
        try {
            // Do something with the form values
            if(onFinish){
                onFinish(values, handleError, form);
                setLoading(false);
            }
            else {
                if (!edit) {
                    http.post(config?.addUrl, values).then((resp) => {
                        if (resp.status === 201) {
                            message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                            if (onSuccessSubmit) {
                                onSuccessSubmit()
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            } else if (onClose) {
                                onClose();
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            }
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                        }
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                        if (onErrorSubmit) {
                            onErrorSubmit()
                        }
                    })
                }
                else{
                    http.patch(config.editUrl, values).then((resp) => {
                        if (resp.status === 200) {
                            message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                            if (onSuccessSubmit) {
                                onSuccessSubmit()
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            } else if (onClose) {
                                onClose()
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            }
                            setLoading(false);
                        }
                        else{
                            setLoading(false);
                        }
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                        if (onErrorSubmit) {
                            onErrorSubmit()
                        }
                    })
                }
            }
            // onClose();
        } catch (error) {
            setLoading(false);
        } finally {

        }
    };

    const handleError=(err)=>{
        if(err?.response?.status === 400){
            let errors = {};
            let err_data = err?.response?.data || {};
            for (const [key, value] of Object.entries(err_data.error_fields)) {
                let translatedText = value.split(':')
                let finalText = translatedText.length > 1 ? (t(translatedText[0]) + ' ' + translatedText[1]): t(translatedText[0])
                errors[key] = {validateStatus: false, errorMsg: finalText}
            }
            if(Object.keys(errors).length !== 0){
                let translatedText = err_data?.error?.split(':')
                let finalText = translatedText ? translatedText.length > 1 ? (t(translatedText[0]) + ' ' + translatedText[1]): t(translatedText[0]): ''
                message.error(finalText || t('default_error_connection_text'));
                setFormErrors(errors)
            }
            else{
                let translatedText = err_data?.validation_error?.split(':')
                let finalText = translatedText ? translatedText.length > 1 ? (t(translatedText[0]) + ' ' + translatedText[1]): t(translatedText[0]): ''
                message.error(finalText);
            }
        }
        else if(err?.response?.status === 500){
            let err_data = err?.response?.data;
            message.error(t(err_data?.error) || t('default_unknown_error'))
        }
        else if(err?.response?.status === 403){
            message.error('Permission Denied')
        }
    }

    const handleCancel = () => {
        form.resetFields();
        setFormErrors({});
        onClose();
    };

    const getFieldComponent = (field) => {
        const { type, placeholder, style, isRequired, extraProps, name } = field;

        switch (type) {
            case 'select':
                return !isRequired ? <SelectField placeholder={placeholder} style={style} {...extraProps} name={name} /> :
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <SelectField placeholder={placeholder} {...extraProps} style={style} name={name} />
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;
            case 'date':
                return !isRequired ? <DateField style={style} placeholder={placeholder} {...extraProps} name={name} initialValue={initialValues[name]}/> :
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <DateField placeholder={placeholder} style={style} {...extraProps} name={name} initialValue={initialValues[name]}/>
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;

            case 'datetime':
                return !isRequired ? <DateTimeField style={style} placeholder={placeholder} {...extraProps} name={name} initialValue={initialValues[name]}/> :
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <DateTimeField placeholder={placeholder} style={style} {...extraProps} name={name} initialValue={initialValues[name]}/>
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;

            case 'password':
                return !isRequired ? <Input.Password style={style} placeholder={placeholder} {...extraProps}  />:
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <Input.Password placeholder={placeholder} style={style} {...extraProps} autocomplete={'new-password'} name={name} />
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;
            case 'checkbox':
                return !isRequired ? <Checkbox placeholder={placeholder} style={style} {...extraProps} name={name} />:
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                                   valuePropName='checked'
                        >
                            <Checkbox placeholder={placeholder} style={style} {...extraProps} name={name}/>
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;
            case 'file':
                return <MyUploadInput value={initialValues[field.name]}
                                      onChange={(info) => {
                                          const data = {};
                                          if(info) {
                                              data[field.name] = info.file;
                                              form.setFieldsValue(data);
                                          }
                                          else{
                                              data[field.name] = null;
                                          }
                                      }} placeholder={placeholder} />
            case 'textarea':
                return !isRequired ? <Input.TextArea placeholder={placeholder} {...extraProps} style={style} name={name} />:
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <Input.TextArea placeholder={placeholder} style={style} {...extraProps} name={name}/>
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;
            // </Upload>
            default:
                return !isRequired ? <Input placeholder={placeholder} {...extraProps} style={style} name={name} />:
                    <div style={{display:"flex"}}>
                        <Form.Item key={name}
                                   name={name}
                                   style={{margin:'0px', width:'100%'}}
                        >
                            <Input placeholder={placeholder} style={style} {...extraProps} name={name}/>
                        </Form.Item>
                        <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                    </div>;
        }
    };

    const formItems = fields
        .sort((a, b) => a.order - b.order)
        .reduce((acc, field) => {
            const { colNo, name, label, isRequired, requiredMessage } = field;
            const validateStatus = formErrors.hasOwnProperty(`${name}`) ? formErrors[name]?.validateStatus:null;
            const errorMsg = formErrors.hasOwnProperty(`${name}`) ? formErrors[name]?.errorMsg:null;
            if(!field.hidden) {
                if (!acc[colNo]) {
                    acc[colNo] = [];
                }

                acc[colNo].push(
                    <Form.Item
                        key={name}
                        label={field.quickAdd? <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                            <span style={{ marginRight: 8 }}>{label}</span>
                            <Button size={"small"} type="primary" shape={'circle'} icon={<PlusOutlined />} style={{
                                borderRadius: '50%',
                                height: 20,
                                width: 20,
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background:'#377EF3'
                            }} onClick={()=>handleQuickAddClick(field)} />

                        </div> : label}
                        name={name}
                        rules={[{required: isRequired, message: requiredMessage}]}
                        validateStatus={validateStatus}
//                         help={errorMsg}
                        extra={<span style={{color: 'red'}}>{errorMsg}</span>}
                        {...(field.type === 'checkbox' ? {valuePropName:'checked'}: {})}
                    >
                        {getFieldComponent(field)}
                    </Form.Item>
                );
            }
            return acc;
        }, {});

    const columns = Object.values(formItems).map((columnItems, index) => {
        return <div key={`${formItems[index]}_${index}`} style={{ marginRight: '16px', flex: 1 }}>
            {columnItems}
        </div>;
    })

    return (
        <Modal
            open={visible}
            title={config.pageTitle}
            onCancel={handleCancel}
            maskClosable={false}
            footer={i18n.language === 'en' ? [
                <Button key="cancel" onClick={handleCancel} loading={loading}>
                    {config.cancelButtonText || 'Cancel'}
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()} style={{background:'#377EF3'}} loading={loading}>
                    {config.saveButtonText || 'Save'}
                </Button>,
            ]: [
                <Button key="submit" type="primary" onClick={() => form.submit()} style={{background:'#377EF3'}} loading={loading}>
                    {config.saveButtonText || 'Save'}
                </Button>,
                <Button key="cancel" onClick={handleCancel} loading={loading}>
                    {config.cancelButtonText || 'Cancel'}
                </Button>
            ]}
            width={modalWidth}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit} requiredMark={false} encType="multipart/form-data">
                <div style={{ display: 'flex' }}>
                    {columns}
                </div>
            </Form>
        </Modal>
    );
};

export default AddPageModal;