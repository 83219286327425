import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConstructorPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const renderFooterFields=(data)=>{
    return [<label style={{color: data.status === 1 ? 'green': 'red'}}>{t(data.status_text)}</label>]
  }

  const buildFooterFields=(data)=>{
    return renderFooterFields(data);
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/partner_constructor_mediation/?partner=${props.partner.id}`,
    hideEdit: true,
    pageTitle: t('partner_constructor_listpage_title'),
    disableDelete: true,
    pageSubtitle: t('partner_constructor_listpage_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'constructor_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'constructor.identifier': t('constructor_identifier_field'), 'constructor.full_name': t('constructor_contact_person_field'), 'constructor.email': t('constructor_email_field'), 'constructor.contact_no': t('constructor_contact_field')},
    cardFooterFields: [],
    orderingFields: [{id: '-company_name', name: t('constructor_name_desc_orderby_field')}, {id: 'company_name', name: t('constructor_name_asc_orderby_field')}],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
     hideAddButton: true,
  }

  const handleDetailClick=(item)=>{
    navigate('/constructor/detail/', { state: {constructor: item.constructor} })
  }

  return (
      <>
        <ListPage
            config={config}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default ConstructorPage;
