import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const MediationRequestListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();
    const handleApproveClick=(record)=>{
        if(record.status === 0) {
            let url = props.type === 'mediation' ?`/api/v1/partner_constructor_mediation/requests/${record.id}/1/approve/` : `/api/v1/partner_constructor_mediation/cancel_requests/${record.id}/1/approve/`;
            http.post(url).then((resp) => {
                if (resp.status === 200) {
                    message.success(props.type === 'mediation'? t('mediation_request_approve_successful_message'):t('mediation_cancelrequest_approve_successful_message'));
                    setReload(!reload)
                } else {
                    message.error(t('mediation_request_approve_fail_message'))
                }
            }).catch((err) => {
                message.error(t('mediation_request_approve_fail_message'))
            })
        }
    }
    const renderApproveIcon=(record)=>{
    if(record.status === 0){
        return <CustomConfirmPopup
                    title={t('mediation_approve_confirmation_text')}
                    onConfirm={()=>handleApproveClick(record)}
                    icon={<CheckOutlined style={{color: 'green'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                ><Typography.Link style={{color:'green'}}><CheckOutlined /></Typography.Link></CustomConfirmPopup>
        }
        else{
        return null;
        }
    }
    const renderRejectIcon=(record)=>{
        if(record.status === 0) {
            return <Typography.Link style={{color: 'red'}}
                                    onClick={() => message.error(t('not_implemented_error_text'))}><CloseOutlined/></Typography.Link>
        }
        else{
            return null
        }
    }

    const columns = [
        {
            title: t('mediationrequest_list_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
            width: '9%',
            fixed: 'left',
        },
        {
            title: t('mediationrequest_list_col2'),
            dataIndex: 'constructor',
            key: 'company_name',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.company_name}</div>
            ),
            width: '10%',
            fixed: 'left',
        },
        {
            title: t('mediationrequest_list_col3'),
            dataIndex: 'constructor',
            key: 'identifier',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.identifier}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col4'),
            dataIndex: 'constructor',
            key: 'contact_no',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.contact_no}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col5'),
            dataIndex: 'constructor',
            key: 'email',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.email}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col6'),
            dataIndex: 'constructor',
            key: 'contact_person',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.full_name}</div>
            ),
            width: '10%'
        },
        {
            title: t('mediationrequest_list_col7'),
            dataIndex: 'status_text',
            key: 'status_text',
            width: '8%',
            fixed: 'right',
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: t('mediationrequest_list_col8'),
            key: 'action',
            fixed:'right',
            width: '8%',
            render: (record) => (
                <Space size="middle">
                {renderApproveIcon(record)}
                {/*{renderRejectIcon(record)}*/}
                </Space>
            )
        },
    ];

    const searchFields = [
        {
            name: 'status',
            type: 'select',
            label: t('mediationrequest_list_col7'),
            placeholder: t('select_placeholder'),
            extraProps: {
                url: '/api/v1/enums/MediationRequestStatusChoices/combo/',
                withTranslation: true
            }
        }
    ];

    return (
            <DataTable
                reload={reload}
                pageTitle={props.type === 'mediation' ? t('partner_detail_mediation_requestlist_title'): t('partner_detail_mediation_cancel_requestlist_title')}
                columns={columns}
                url={props.type === 'mediation' ? `/api/v1/partner_constructor_mediation/requests/?partner=${props.partner.id}`:`/api/v1/partner_constructor_mediation/cancel_requests/?partner=${props.partner.id}`}
                searchFields={searchFields}
                defaultParams={{status: 0}}
                pagination={true}
                pageButtons={[

                ]}
            />
    );
};

export default MediationRequestListPage;